import React from "react"
import Img from "gatsby-image"
import Logo from "../../static/img/Logo-big.png"


const Navbar = () => (
  <footer className="footer">
    <div className="columns upper-footer is-vcentered">
      <div className="column">
        <div class="inline-width footer-vision">
          <h4 className="clean-style">MEPGEN Expertise</h4>
          <ul className="left-align">
            <li>MEP-HVAC-Fire Life Safety</li>
            <li>Building Automation System</li>
            <li>General Construction & Maintenance</li>
            <li>Energy Efficiency Management</li>
            <li>Commissioning & Documentation</li>
            <li>Consulting & Project Management</li>
          </ul>
        </div>
      </div>
      <div className="column">
        <img className="footer-logo" src={Logo} alt=""/>
      </div>
      <div className="column">
        <div class="inline-width">
          <h4 className="tag-style">Contact Us</h4>
          <div class="block">
            <div className="minimal-style">Phone Number</div>
            <div className="">(818)-1234-5678</div>
            <div className="minimal-style">Email</div>
            <div className="">electromechvac@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
    <p class="container lower-footer">© MEPGEN 2019</p>
  </footer>
)


export default Navbar
