import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import heroImage from "../../static/img/hero-image.jpg"
import Hero from "../components/hero";
import MainSection from "../components/main-section"
import VisionImage from "../image-components/vision-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import HVACImage from "../image-components/hvac-image";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <Hero className="hero is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="container width-limit pattern-border">Facilities Maintenance, Service, 
Engineering & Management Excellence</h1>
        </div>
      </div>
    </Hero>

    <MainSection></MainSection>

    <div className="section-spacing"></div>

    <div className="container">
      
      <div className="columns is-vcentered is-centered">
        <div className="column big-container-padding">
          <HVACImage/>
        </div>
        <div className="column container-padding">
          <h1>Our Mission</h1>
          <p>Dedication to the highest level of FACILITIES' Customer Service, Peace of Mind and satisfaction on Unmet Needs by leveraging on best available Talents and Technologies.</p>
          <a href="" className="green-link">Read more about our mission ></a>
        </div>
      </div>

      <div className="columns is-vcentered is-centered">
        <div className="column container-padding">
          <h1>Our Vision</h1>
          <p>To be the most preferred Facilities change agent towards improvements - and to contantly strive to achieve the highest level of excellence and industry certification in the company and for all its stakeholders in the U.S. and Southeast Asia region.</p>
          <a href="" className="green-link">Read more about our vision ></a>
        </div>
        <div className="column big-container-padding">
          <VisionImage/>
        </div>
      </div>
    </div>

    <div className="section-spacing"></div>

    <div class="container pattern-border">
      <div id="ten-list" className="container width-limit">
        <div className="columns is-vcentered accent-border-bottom">
          <div className="column is-4 big-label">
            TOP 10
          </div>
          <div className="column">
            <h4 className="">value-added services that we can help and can be very beneficial to your organization:</h4>
          </div>
        </div>
  
        <div className="columns is-multiline">
          <div class="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Service, operate and maintain your MEP plant & equipment efficiently and reliably.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Work with you to ensure best practice maintenance documentation and re-organization such as for
  manuals, as-built plans, parts inventory and the like.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Assist you to verify accurate energy savings and assist where needed to ensure best practice energy
  conservation & management.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Provide you a simplified, practical and cost-effective solution to complex issues and as needed.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Make facilities systems &amp; equipment repairs timely , of good quality and at a very reasonable price.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">We can assist you on ensuring that projects are coordinated and managed timely, of good quality and
  within budget. We can also help on MEP design management and/or engineering.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Assist you on implementing best practice computerized maintenance management system.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Assist you on operating your BAS(building automation system) as efficiently and effectively as possible.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Assist you and/or provide you guidance on properly meeting the requirements of national and local
  codes, regulations and standards.</div>
            </div>
          </div>
          <div className="column is-full">
            <div className="columns is-vcentered">
              <FontAwesomeIcon className="green-icon" icon={faLeaf} />
              <div className="column">Assist you on properly commissioning or retro-commissioning your building systems equipment so that design intent and/or original specifications are met</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      
    </div>
  </Layout>
)

export default IndexPage
