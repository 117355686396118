import React from "react"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import {StaticQuery, graphql} from 'gatsby'

const Hero = ({className, children}) => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-image.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
     render={data => {
       const imageData = data.desktop.childImageSharp.fluid
       return (
          <BackgroundImage Tag="section"
                           className={className}
                           fluid={imageData}
                           backgroundColor={`#040e18`}
          >
          {children}
          </BackgroundImage>
       )
     }
     }
  />
)


export default Hero
