import React from "react"



const MainSection = () => (
  
  <section className="container main-section width-limit">
    <h1>Our Company</h1>
    <p>MepGen Corporation is a "one-stop-shop" company for all your Facilities Maintenance, Service, Engineering and Management needs.  MEP stands for its expertise in Mechanical, Electrical and Plumbing services. GEN denotes General Maintenance and Construction-related works. MepGen also signifies our team of  new breed of MEP professionals, workers or talents who can provide our customers and clients , through our superior services- the much needed highest level of work efficiency, effectiveness, and quality that the new generation state-of-the-art facilities, buildings, and equipment critically require.</p>

    <h1>The services we provide:</h1>

    <div className="columns is-8 is-variable">
      <div className="column">
        <h4 className="retro-style">Operations & Maintenance</h4>
        <p>We can properly operate & maintain any of your building systems & equipment such as your Central Chiller , Boiler and BAS Plant / Systems so as to ensure efficiency, effectiveness and reliability for best support of your business operations.</p>
      </div>
      <div className="column">
        <h4 className="retro-style">Installation</h4>
        <p>For new projects or when repairs are not cost-effective or practical, we are the "one-stop-company" that you can trust and rely on so that your new installation projects are  installed with highest level of good workmanship, completed as smooth as possible and within budget or at very reasonable cost.</p>
      </div>
    </div>
    <div className="columns is-8 is-variable">
      <div className="column">
        <h4 className="retro-style">Service & Repairs</h4>
        <p>We can provide accurate and reasonably priced service by means of proper diagnostics and troubleshooting so that the correct problem is identified, impact is accurately assessed, true root cause is found and best possible solution is applied. The leader of our company created a problem solving tool called "FIX by PICS(Problem-Impact-Cause-Solution)" and applied successfully in numerous real-world problems.</p>
      </div>
      <div className="column">
        <h4 className="retro-style">Project & Energy Management</h4>
        <p>We have in-house expertise in best practice  Project Management and Energy Management. Not only that we can ensure that your large or small projects are completed on time, good quality and within budget, we also can assure you that reliable energy conservation and efficiency measures and outcomes are part of every project aspect or stage that we do.</p>
      </div>
    </div>
    <div className="columns is-8 is-variable">
      <div className="column">
        <h4 className="retro-style">Commissioning & Consulting</h4>
        <p>We also can provide our customers and clients excellent support on  good facilities or building systems design and other consultancy  services We can work very closely with your team so that best design for your needs are provided. With good design provision, our excellence in testing & commissioning  will ensure that the design intent is met.</p>
      </div>
      <div className="column">
        <h4 className="retro-style">Training</h4>
        <p>Our work culture and/or practices is revolving around training, continuing education, and professional development.Key part of our company mission/vision is to be a fully certified company with certified and adequately trained employees.</p>
      </div>
    </div>


  </section>
  
)


export default MainSection
