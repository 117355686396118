import React from "react"
import Img from "gatsby-image"
import Logo from "../../static/img/Logo.png"


const Navbar = () => (
  <nav className="navbar is-black is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="container">
      <div className="navbar-brand">
        <a class Name="navbar-item" href="">
          <img src={Logo}/>
        </a>
  
        <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
  
      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <a className="navbar-item is-active">
            About
          </a>
  
          <a className="navbar-item">
            Services
          </a>
  
          <a className="navbar-item">
            Vision
          </a>
  
          <a className="navbar-item">
            Mission
          </a>
  
          
        </div>
  
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-success is-outlined is-small">
                <strong>(818)-1234-5678</strong>
              </a>
              <a className="button is-success is-outlined is-small">
                <strong>electromechvac@gmail.com</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</nav> 
)


export default Navbar
